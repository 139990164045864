import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail/lg-thumbnail.es5.js'
import lgZoom from 'lightgallery/plugins/zoom/lg-zoom.es5.js'

document.addEventListener("DOMContentLoaded", () => {
  const lightGalleryEl = document.getElementById("lightgallery");

  if (lightGalleryEl) {
    lightGallery(document.getElementById("lightgallery"), {
      plugins: [lgZoom, lgThumbnail],
      licenseKey: "DC27E603-FFE8-4AF1-BF31-B7C8F61DDF31",
      speed: 500,
      selector: ".lightgallery-item"
    });
  }

  document.querySelector(".nav-trigger").addEventListener("click", (event) => {
    document.querySelector(".nav-trigger").classList.toggle("on");
    document.querySelector("nav .nav ul").classList.toggle("on");
  });
});
